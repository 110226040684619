<template>
<div id="MemberProfile">  
  <Header/>
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfuncup="onFuncUp"/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <CommonAlert3Func ref="commonAlert3Func" :title="title"  v-model="content"  :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          會員資料
        </a>
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" > 
        </a> 
      </div>
    </nav>
    <!-- End header -->
    <div class="nicolespacetop"></div>
    <div class="container membermb mt-md-5">
      <div class=" text-center">

        <img v-real-img="icon" src="assets/img/camera-no.png" class="avatar avatar-xl rounded-pill" @click="clickAlbumButton()" v-if="!isUploadImage">
        <img :src="icon != '' ? 'data:image/jpeg;base64,'+icon : 'assets/img/camera-no.png'" class="avatar avatar-xl rounded-pill" @click="clickAlbumButton()" v-else>

      </div>
    </div>
    <div class="container membermb mt-md-5">
      <table class="table table-xs table-borderless align-items-center mb-0 ">
        <tbody>
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img001.png" class="avatar avatar-22 rounded-0 me-2">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0  text-s text-dark">名稱</h6>
                </div>
              </div>
            </td>
            <!-- <td class="align-middle text-right px-0 hand" data-bs-toggle="modal" data-bs-target="#noticenametext"> -->
            <td class="align-middle text-right px-0 hand" @click="showModal('#noticenametext')">
              <div class="mb-0  text-s text-muted">{{name}}<i class="far fa-chevron-right text-light2 ms-1 "></i></div>
            </td>
          </tr>        
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/ic_table_phone_18.png" class="avatar avatar-22 me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0  text-s text-dark">手機號碼</h6>
                </div>
              </div>
            </td>


            <td class="align-middle text-right px-0 hand" @click="showModal('#noticephonetext')" v-if="!hasPhone">
              <div class="mb-0  text-s text-muted">{{phone}}
                <i class="far fa-chevron-right text-light2 ms-1 "></i>
              </div>
            </td>
            <td class="align-middle text-right px-0" v-else>
              <div class="mb-0  text-s text-muted">
                {{phone}} <i class="far fa-chevron-right text-light2 ms-1 " style="visibility: hidden;"></i>
              </div>
            </td>


          </tr>          
          <tr>
            <td class="ps-0 ">
              <div class="d-flex">
                <div>
                  <img src="assets/img/img003.png" class="avatar avatar-22 me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0  text-s text-dark">電子郵件</h6>
                </div>
              </div>
            </td>
            <!-- <td class="align-middle w-70 text-right text-wrap px-0 hand"  data-bs-toggle="modal" data-bs-target="#noticemailtext"> -->
            <td class="align-middle w-70 text-right text-wrap px-0 hand" @click="showModal('#noticemailtext')">
              <div class="d-flex align-items-center justify-content-end">
                <div class="mb-0  text-smm text-muted text-break">{{email}}</div>
              <i class="far fa-chevron-right text-light2 ms-1 text-smm "></i></div>
            </td>
          </tr>          
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/ic_birth_18.png" class="avatar avatar-22 me-2 rounded-0 nicolecontain">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-s text-dark">生日</h6>
                </div>
              </div>
            </td>
            <!-- <td class="align-middle text-right px-0 hand" data-bs-toggle="modal" data-bs-target="#noticebirthtext">               -->
            <td class="align-middle text-right px-0 hand" @click="showModal('#noticebirthtext')">              
              <div class="mb-0  text-s text-muted">
                {{birthday}} <i class="far fa-chevron-right text-light2 ms-1 "></i>
              </div>
            </td>
          </tr>          
          <tr>
            <td class="ps-0">
              <div class="d-flex">
                <div>
                  <img src="assets/img/ic_gender_18.png" class="avatar avatar-check me-2 rounded-0 nicolecontain ">
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-s text-dark">性別</h6>
                </div>
              </div>
            </td>
            <!-- <td class="align-middle text-right px-0 hand" data-bs-toggle="modal" data-bs-target="#notice3"> -->
            <td class="align-middle text-right px-0 hand" @click="showModal('#notice3')">
              <div class="mb-0  text-s text-muted">{{genderName}}<i class="far fa-chevron-right text-light2 ms-1 " ></i></div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center membermb mt-md-7">
        <button type="button" class="btn btn-lg  btn-success rounded-pill w-95  text-s mx-auto " v-if="isCanSubmit && isChangeData" @click="saveMemberProfile()" >儲存</button>  
        <button type="button" class="btn btn-lg  btn-success rounded-pill w-95  text-s mx-auto disabled" v-else >儲存</button>  
      </div>
    </div>
      

    <!-- 性別Modal --> 
    <div class="modal " id="notice3" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true" data-backdrop="static">
      <div class="modal-sm-big  modaldown  modal-dialog modal-dialog-down" role="document">
        <div class="modal-content rounded-bottom-0">
            <div class="modal-header pt-3 pb-2 border-bottom">
              <h4 class="text-center text-n fw-bold text-dark mb-0 ">選擇性別</h4>
            </div>    
            <div class="mx-3 mb-1 text-center" v-for='(gender, index) in genderList' v-bind:key='gender.gid'>
              <h5 class="text-s text-dark my-3" data-bs-dismiss="modal" @click='confirmGender(index)'> {{ gender.name }} </h5>
            </div>
        </div>
      </div> 
    </div>    
    <!-- Modal --> 
    <!-- 電子郵件Modal --> 
    <div class="modal fade " id="noticemailtext" tabindex="-1" role="dialog" aria-labelledby="noticemailtextLabel" aria-hidden="true">
      <div class="modal modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal">
          <div class="modal-body text-s text-simbold  text-dark m-2 text-center text-simbold">
            請輸入新電子郵件
            <input type="text" class="form-control mt-3" placeholder=" " aria-label="" aria-describedby="basic-addon1" v-model="email">
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="cancel('email')">取消</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="confirm('email')">完成</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal --> 
    <!-- 名稱Modal --> 
    <div class="modal fade " id="noticenametext" tabindex="-1" role="dialog" aria-labelledby="noticenametextLabel" aria-hidden="true">
      <div class="modal modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal">
          <div class="modal-body text-s text-simbold  text-dark m-2 text-center text-simbold">
            請輸入新名稱
            <input type="text" class="form-control mt-3" placeholder=" " aria-label="" aria-describedby="basic-addon1" maxlength="10" autocomplete="off" v-model="name">
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="cancel('name')">取消</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="confirm('name')">完成</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->  
    <!-- 手機Modal --> 
    <div class="modal fade " id="noticephonetext" tabindex="-1" role="dialog" aria-labelledby="noticephonetextLabel" aria-hidden="true">
      <div class="modal modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal">
          <div class="modal-body text-s text-simbold  text-dark m-2 text-center text-simbold">
            請輸入新手機號碼 
            <input type="text" class="form-control mt-3" placeholder=" " aria-label="" aria-describedby="basic-addon1" oninput="if(value.length>10)value=value.slice(0,10); value=value.replace(/[^0-9]/g,'');" autocomplete="off" v-model="phone">
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="cancel('phone')">取消</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="confirm('phone')">完成</h5>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- Modal --> 
    <!-- 生日Modal --> 
    <div class="modal fade " id="noticebirthtext" tabindex="-1" role="dialog" aria-labelledby="noticebirthtextLabel" aria-hidden="true">
      <div class="modal modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal">
          <div class="modal-body text-s text-simbold  text-dark m-2 text-center text-simbold pb-0"> 請選擇日期 </div>
          <date-picker class="modal-body align-self-center" input-class="form-control" v-model="birthday" valueType="format" :editable="false" > </date-picker>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="cancel('birthday')">取消</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 text-s" data-bs-dismiss="modal" @click="confirm('birthday')">完成</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->  
  </body>
  <Footer/>
</div>
</template>

<script>
import swal from "sweetalert2";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CommonAlert1 from '@/components/CommonAlert1.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import CommonAlert3Func from '@/components/CommonAlert3Func.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import { config } from "@/include/config"; 


export default {
  name: 'MemberProfile',
  components: {
      Header,
      Footer,
      CommonAlert1,
      CommonAlert2Func,
      CommonAlert3Func,
      DatePicker,
  },
  data () {
    return { 
      // api setting
        setDebugMode:true ,
        apiDebug: false,
        apiName: "",
        perPage: 1,  //筆數
        pageMode: false,
        perPageOptions: [5, 10, 25, 50 , 75 , 100],
        filterData: {},
        form:{
          seq: 10,
          switch: true,
        },

      // data
        name: "",
        phone: "",
        email: "",
        birthday: "",
        gid: "",
        genderName: "",
        base64Prefixid: "data:image/png;base64,",
        icon: "",
        genderList: {},

      // defaultData
        dfIcon: "",
        dfName:"",
        dfPhone:"",
        dfEmail:"",
        dfBirthday:"",
        dfGid:"",
        dfGenderName:"",

      // status
        isChangeData: false,
        isChangePic: false,
        isCanSubmit: false, 
        isUploadImage: false,


      // alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,
      

      hasPhone: false,
    }
  },
  methods: {
    openLogin(){
      this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"MemberProfile", type:""}), this.openLoginCallback);
    },
    openLoginCallback(){

    },
    
    setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
    },
    onBack(){
      $("#noticenametext").modal("hide");
      $("#noticephonetext").modal("hide");
      $("#noticemailtext").modal("hide");
      $("#noticebirthtext").modal("hide");
      $("#notice3").modal("hide");
      if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
        this.$router.push(this.$route.params.back);
      } else {
        this.$router.push({
          name: "Mine"
        });
      }
    },
    getMemberProfile(){
      this.apiName="page_member_profile";
      this.filterData['uid'] = this.$GlobalData.uid;
      oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {
        if (res != undefined && res.user != null && res.user.length > 0) {
          if (res.user[0].name != "") {
            this.name = res.user[0].name;
            this.dfName = res.user[0].name;
          }

          if (res.user[0].phone != "" && res.user[0].phone != null) {
            this.hasPhone = true;
            this.phone = res.user[0].phone;
            this.dfPhone = res.user[0].phone;
          }

          if (res.user[0].email != "") {
            this.email = res.user[0].email;
            this.dfEmail = res.user[0].email;
          }

          if (res.user[0].birthday != "") {
            this.birthday = this.dateReFormat(res.user[0].birthday);
            this.dfBirthday = this.dateReFormat(res.user[0].birthday);
          }

          if (res.user[0].gid != "") {
            this.gid = res.user[0].gid;
            this.dfGid = res.user[0].gid;
          }
          if (res.user[0].gender_name != "") {
            this.genderName = res.user[0].gender_name;
            this.dfGenderName = res.user[0].gender_name;
          }

          if(res.user[0].image_url != ""){
			if(config && config.old_images && config.old_images=="Y") 
			{  		   
            this.dfIcon = res.user[0].image_url;
            this.getUserIcon();
			} 
			else 
			{  
            this.icon = res.user[0].image;
			} 
            
          }
        }

        if (res != undefined && res.gender != null && res.gender.length > 0){
          this.genderList = res.gender;
        }

      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    getUserIcon(){
      if(this.dfIcon != ""){
        this.apiName = "page_mine_icon";
        oAC.getAllRecord(oAC.getApiParam(this), {url:this.dfIcon}).then(res => {
            if(res && res.image && res.image != ""){                        
              this.icon = res.image;
              this.dfIcon = "data:image/jpeg;base64," + res.image;
            } else {
              this.icon = ""; 
            }
          }
        ).catch((err)=> {
            this.icon = "";
            oltConsole.log(err);
        });
      }
    },
    clickAlbumButton(){
      this.$JsBridge.callHandler('openAlbumVue', JSON.stringify({backUrl:"MemberProfile", type:""}), this.openAlbumCallback);
    },
    openAlbumCallback(res){
      this.isUploadImage = true;

      if (res != null && res != undefined) {
        this.isChangePic = true;
        this.icon = res;

        // 只更換大頭貼，不能儲存
        this.isChangeData = true;
      }
    },
    cancel(type){
      switch(type){
        case "name":
          this.name = this.dfName;
        break;
        case "phone":
          this.phone = this.dfPhone;
        break;
        case "email":
          this.email = this.dfEmail;
        break;
        case "birthday":
          this.birthday = this.dfBirthday;
        break;
      }
    },
    confirm(type){
      this.isChangeData = true;
      switch(type){
        case "name":
          this.dfName = this.name ;
        break;
        case "phone":
          this.dfPhone = this.phone;
        break;
        case "email":
          this.dfEmail = this.email;
        break;
        case "birthday":
          this.dfBirthday = this.birthday;
        break;
      }
    },
    confirmGender(index){
      this.genderName = this.genderList[index].name;
      this.gid = this.genderList[index].gid;
      this.isChangeData = true;
    },
    saveMemberProfile(){
      // 假如都沒有變更則不執行 API
      // 反之，有的話則更新資料，
      // 假如大頭貼有變，則更新大頭貼
      if (!this.isChangeData && !this.isChangePic) { return ; }
      this.apiName="member_put_user/"+this.$GlobalData.uid;
      this.form["name"] = this.name;
      this.form["phone"] = this.phone;
      this.form["email"] = this.email;
      this.form["gid"] = this.gid;
      this.form["birthday"] = this.birthday;
      this.fun = "MemberProfile";
      this.act = "put"; 
      oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
        if (res.error != null) {
          this.errorAlert(res.error);
        }

        if (this.icon != "" && this.icon != this.dfIcon) {
          this.apiName = "image_upload";
          let json = oAC.getApiParam(this);
          json.uid = this.$GlobalData.uid;
          json.sub_dir = 'user_icon';
          var file = this.icon;
          oAC.postFileRecord(json, file).then(res => {
            this.isChangeData = false;
            this.successAlert();
            }).catch((err)=> {
              oltConsole.log(err);
              this.title = "修改失敗";
              this.content = "請確認圖片1";
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
          });
        } else {
          this.isChangeData = false;
          this.successAlert();
        }
      }).catch((err)=> {
        if (err != null) {
          this.errorAlert(err);
        }
        oltConsole.log(err);
      });
    },
    // iOS 看不懂JS的日期Class
    dateReFormat(oldValue){
      if (oldValue == null) { return "";}
      var arr = oldValue.split(/[- :]/);
      var date = new Date(arr[0], arr[1]-1, arr[2]);
      var year = date.getFullYear();
      var month = ((date.getMonth()+1) < 10) ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      var day = (date.getDate() < 10) ? "0"+date.getDate() : date.getDate();
      var temp = year+"-"+month+"-"+day;
      return temp;
    },
    successAlert(){
      this.getUserData();
    },
    errorAlert(val){
      this.title = "錯誤";
      this.content = val;
      this.btnNameUp = "確定";
      this.$refs.commonAlert1.onShow();
    },
    getUserData(){
      this.apiName="member_get_user_data";
      oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid} ).then(res => {
        if (res != undefined && res.record != null && res.record.length > 0 && res.rt != null && res.rt.user != null && res.rt.user.length > 0 ) {
          this.setUserData(res);
        }
      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    setUserData(res){
      var json = {
        record: res.record,
        rt: res.rt,
        token: this.$GlobalData.token
      };
      this.$GlobalData.user = res.rt.user;
      this.$JsBridge.callHandler('setUserData',JSON.stringify( json ), this.setUserDataCallback);
    },
    setUserDataCallback(){
      this.title = "成功";
      this.content = "已修改完成。";
      this.btnNameUp = "確定";
      this.$refs.commonAlert1.onShow();
      // this.$forceUpdate();
    },
    // iOS 看不懂JS的日期Class
    convertDateForIos(date) {
      var arr = date.split(/[- :]/);
      date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
      return date;
    },
    showModal(target){
      $(target).modal("show");
    }
  },
  created(){
    if (this.$route.query.uid != undefined){
      this.$GlobalData.uid = this.$route.query.uid;
    }
    if (this.$JsBridge.isAndroid) {
        this.setOnBack();
    }
    this.getMemberProfile();

  },
  updated(){
    if (
      this.name == null || this.phone == null || this.email == null || this.birthday == null || this.gid == null ||
      this.name === '' || this.phone === '' || this.email === '' || this.birthday === '' || this.gid === ''
      ) {
      this.isCanSubmit = false;
    } else {
      this.isCanSubmit = true;
    }


  }
};
</script>
<style type="text/css">
  .input_color{
    border: transparent !important;
  }
  /*隱藏文字區塊的縮放三角形*/
  .input_textarea{
    resize: none;
    min-height: 32vw !important;
  }
  /*隱藏輸入數字的上下箭頭*/
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input:focus{outline:none}

  .hand{
    cursor:pointer;
  }
</style>