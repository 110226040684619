<template>
    <div id="CommonAlert3Func">
        <div class="modal fade " id="commonAlert3Func" tabindex="-1" role="dialog" aria-labelledby="notice1Label" aria-hidden="true">
            <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center" >
                        <div class="h3 mt-3 mb-4 fw-bolder">{{title}}</div>
                        <input class="form-control input-group-text text-center" type="text" name="alert_value" :value="value" placeholder="請填寫推薦碼" maxlength="8" autocomplete="off" @input="$emit('input', $event.target.value)">
                        <div class="mt-4">
                            <button type="button" class="btn btn-success rounded-pill w-75" @click="onFuncUp">{{btnnameup}}</button>
                            <button type="button" class="btn btn-outline-success rounded-pill w-75 text-s mt-2 nicole-noshadow" @click="onFuncDown">{{btnnamedown}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CommonAlert3Func',    
    props: {
        onfuncup: Function,
        onfuncdown: Function,
        title: String,
        value: {
            type: String
        },
        btnnameup: String,
        btnnamedown: String,
    }, 
    methods: {
        onShow(){
            $('#commonAlert3Func').modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonAlert3Func').modal("hide");
        },
        onFuncUp(){
            if(this.onfuncup != null && this.onfuncup != undefined){
                $(`#commonAlert3Func`).on('hidden.bs.modal', () => {
                    $(`#commonAlert3Func`).off('hidden.bs.modal');
                    this.onfuncup();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonAlert3Func').modal("hide");
        },
        onFuncDown(){
            if(this.onfuncdown != null && this.onfuncdown != undefined){
                $(`#commonAlert3Func`).on('hidden.bs.modal', () => {
                    $(`#commonAlert3Func`).off('hidden.bs.modal');
                    this.onfuncdown();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonAlert3Func').modal("hide");
        },
    }
};
</script>
